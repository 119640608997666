import React, { lazy } from 'react';

// define manual import for preventing hook restriction
const DUMMY = {
	CRUD: lazy(() => import('../views/CRUD')),
};

const MasterData = {
	Category: lazy(() => import('../views/Category')),
	DocumentType: lazy(() => import('../views/sodiq/DocumentType')),
	Signature: lazy(() => import('../views/handri/signature')),
	Approval: lazy(() => import('../views/bakti/master-approval')),
};

const Document = {
	Request: lazy(() => import('../views/sodiq/DocumentRequest')),
	QASProces: lazy(() => import('../views/sodiq/DocumentQASProcess')),
	Approval: lazy(() => import('../views/sodiq/DocumentApproval')),
	AllDepartment: lazy(() => import('../views/sodiq/DocumentAllDepartment')),
	Obsolete: lazy(() => import('../views/aji/DocObsolete')),
	Distributed: lazy(() => import('../views/sodiq/DocumentDistributed')),
	TemporaryAccess: lazy(() => import('../views/sodiq/TemporaryAccess')),
	MyDocument: lazy(() => import('../views/sodiq/MyDocument')),
};

const ImportData = {
	ExcelMigration: lazy(() => import('../views/sodiq/ExcelMigration')),
};

const Tools = {
	DrawingDiagram: lazy(() => import('../views/hari/DrawingDiagram')),
};

const contents = [
	{
		path: null,
		element: <DUMMY.CRUD />,
		index: 'CRUD',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Category />,
		index: 'Category',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.DocumentType />,
		index: 'DocumentType',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Signature />,
		index: 'Signature',
		exact: true,
	},
	{
		path: null,
		element: <Document.Request />,
		index: 'DocumentRequest',
		exact: true,
	},
	{
		path: null,
		element: <Document.QASProces />,
		index: 'DocumentQASProcess',
		exact: true,
	},
	{
		path: null,
		element: <Document.Approval />,
		index: 'DocumentApproval',
		exact: true,
	},
	{
		path: null,
		element: <Document.Distributed />,
		index: 'DocumentDistributed',
		exact: true,
	},
	{
		path: null,
		element: <Document.AllDepartment />,
		index: 'DocumentAllDepartment',
		exact: true,
	},
	{
		path: null,
		element: <Document.Obsolete />,
		index: 'DocumentObsolete',
		exact: true,
	},

	{
		path: null,
		element: <Document.TemporaryAccess />,
		index: 'TemporaryAccess',
		exact: true,
	},
	{
		path: null,
		element: <Document.MyDocument />,
		index: 'MyDocument',
		exact: true,
	},
	{
		path: null,
		element: <ImportData.ExcelMigration />,
		index: 'ExcelMigration',
		exact: true,
	},
	{
		path: null,
		element: <Tools.DrawingDiagram />,
		index: 'DrawingDiagram',
		exact: true,
	},
	{
		path: null,
		element: <MasterData.Approval />,
		index: 'MasterApproval',
		exact: true,
	},
];
export default contents;
