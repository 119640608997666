import axios from 'axios';
import authHeader from '../auth-header';

const API_URL_DEFAULT = process.env.REACT_APP_API;
const APP_MODEL = 'document-request';

const create = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}`, payload, { headers: await authHeader() });
};

const read = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}?${query}`, { headers: await authHeader() });
};

const getCategory = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-category?${query}`, {
		headers: await authHeader(),
	});
};

const getMaterial = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-material?${query}`, {
		headers: await authHeader(),
	});
};

const getUser = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-user?${query}`, {
		headers: await authHeader(),
	});
};

const getDocument = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-document?${query}`, {
		headers: await authHeader(),
	});
};

const getDepartment = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-department?${query}`, {
		headers: await authHeader(),
	});
};

const download = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/download?${query}`, {
		headers: await authHeader(),
	});
};

const update = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/update`, payload, {
		headers: await authHeader(),
	});
};

const updateDynamic = async (payload) => {
	return axios.put(`${API_URL_DEFAULT}${APP_MODEL}/update-dynamic`, payload, {
		headers: await authHeader(),
	});
};

const convert = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/convert`, payload, {
		headers: await authHeader(),
	});
};
const updateQAS = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/update-qas`, payload, {
		headers: await authHeader(),
	});
};

const getRevisionHistory = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-revision-history?${query}`, {
		headers: await authHeader(),
	});
};

const receivingDepartment = async (payload) => {
	return axios.post(`${API_URL_DEFAULT}${APP_MODEL}/receiving-department`, payload, {
		headers: await authHeader(),
	});
};

const deptByOrg = async (query_string) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/dept-by-org/?${query_string}`, {
		headers: await authHeader(),
	});
};

const readNotif = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/read-notif?${query}`, {
		headers: await authHeader(),
	});
};

const getCategoryApproval = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-category-approval?${query}`, {
		headers: await authHeader(),
	});
};

const getApproval = async (query) => {
	return axios.get(`${API_URL_DEFAULT}${APP_MODEL}/get-approval?${query}`, {
		headers: await authHeader(),
	});
};

export default {
	create,
	read,
	getCategory,
	getMaterial,
	getUser,
	getDocument,
	getDepartment,
	download,
	update,
	updateDynamic,
	updateQAS,
	getRevisionHistory,
	receivingDepartment,
	convert,
	deptByOrg,
	readNotif,
	getCategoryApproval,
	getApproval,
};
